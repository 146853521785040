:root { 
--white_A700_7f:#ffffff7f; 
--amber_A400:#ffcb00; 
--light_green_900_aa:#476c2caa; 
--light_green_900:#476c2c; 
--black_900_42:#00000042; 
--black_900:#000000; 
--light_green_900_19:#476c2c19; 
--gray_500_01:#a0b291; 
--light_green_700:#639a3c; 
--light_green_800:#588a34; 
--light_blue_A700:#0075ff; 
--gray_500_03:#919191; 
--gray_500_02:#a1b291; 
--deep_orange_500:#ff6b18; 
--light_green_900_0c:#476c2c0c; 
--red_A700:#ff0000; 
--white_A700_4c:#ffffff4c; 
--light_greenn_900_bb:#7E986B; 
--deep_orange_500_4c:#ff6b184c; 
--gray_700:#6a6a6a; 
--gray_400:#b3b3b3; 
--gray_500:#a6a6a6; 
--blue_gray_100:#d9d9d9; 
--light_green_800_19:#46a60019; 
--red_A700_19:#ff000019; 
--orange_700:#ca9200; 
--gray_100:#f5f5f5; 
--black_900_19:#00000019; 
--amber_A400_19:#ffcb0019; 
--white_A700:#ffffff; 
}